<template>
  <div class="card flex" @click="toStoreDetailFn">
    <div class="item_box">
      <div class="item_l">
        <div class="store_img">
          <img :src="dataObj.logoImage || defaultImg" alt="" />
          <div class="mask" v-if="!flagType && dataObj.followed">
            <span class="m-l-4">已关注</span>
          </div>
        </div>
      </div>
      <div class="item_r flex-1">
        <div
          class="item_title"
          :style="{
            justifyContent:
              type == 'goodsDetail' ? 'flex-start' : 'space-between',
          }"
        >
          <div class="ellipsis flex">
            <span>
              {{ dataObj.name }}
            </span>

            <div class="flag_box m-t-4">
              <span :class="typeClass" v-if="dataObj.type">
                {{ renderType() }}
              </span>
              <span
                class="srrz m-l-4"
                v-if="!flagType && dataObj.flagThreesoft"
              >
                思锐认证
              </span>
              <span
                class="srrz m-l-4"
                v-if="!flagType && dataObj.flagOptimization"
              >
                优质商家
              </span>
              <span class="srrz m-l-4" v-if="!flagType && dataObj.flagPower">
                实力商家
              </span>
            </div>
          </div>
          <img
            src="@/assets/store/threesoft.png"
            v-if="type == 'goodsDetail' && dataObj.flagThreesoft"
            class="threesoft_img m-l-4"
          />
        </div>
        <div class=" flex items-center">
          <div class="btn_box" v-if="type">
            <slot name="btn"></slot>
          </div>
          <div class="city" v-else>
            {{ dataObj.areas ? dataObj.areas[1] : "" }}
          </div>
          <div class="companyName m-t-4" v-if="dataObj.autoPartsCity">
            <i class="iconfont icon-fill_home m-r-4 font-size-12"></i>
            <span>{{ dataObj.autoPartsCity }}</span>
            <i class="iconfont icon-direction_right m-l-4 font-size-12"></i>
          </div>
        </div>
        <!-- 地址 -->
        <div class="item_address" v-if="flagType">
          {{ dataObj.areas ? dataObj.areas.join("/") : "" }}
          {{ dataObj.address }}
        </div>
        <!-- 配件品类 -->
        <div
          class="item_oil ellipsis truncate"
          v-if="!flagType && dataObj.categories"
        >
          <span>{{ renderCategories }}</span>
        </div>
        <!-- 排名 -->
        <!-- <div class="companyName m-t-4" v-if="!flagType ">
          <i class="iconfont icon-home_hot m-r-4 font-size-12"></i>
          <span>油品畅销类第3名</span>
          <i class="iconfont icon-direction_right m-l-4 font-size-12"></i>
        </div> -->

        <!-- 汽配城 -->

        <div
          class="img_div overflow-hidden"
          v-if="!flagType && dataObj.images && dataObj.images.length"
        >
          <template v-for="(img, index_i) in dataObj.images">
            <el-image
              :src="img"
              alt=""
              :key="index_i"
              v-if="index_i < 5"
              fit="cover"
              class="w-56 h-56 mr-8"
              :preview-src-list="dataObj.images"
              :class="[index_i == 4 ? 'm_r_0' : '']"
              @click.stop="showImgFn(dataObj.images, index_i)"
            />
          </template>
          <div
            class="mask"
            v-if="
              dataObj.images &&
                dataObj.images.length &&
                dataObj.images.length - 5 > 0
            "
            @click.stop="showImgFn(dataObj.images, 4)"
          >
            <div class="mask_num">+{{ dataObj.images.length - 5 }}</div>
          </div>
        </div>
        <!-- 公司简介 -->
        <div
          class="base-text-type ellipsis-2 m-t-8 truncate w-300"
          v-if="dataObj.description"
        >
          {{ dataObj.description }}
        </div>
      </div>
      <div>
        <div class="footer m-t-8" v-if="dataObj.counts">
          <div class="footer_r flex_r_c">
            <template v-if="dataObj.counts">
              <span class="flex_r_c m-r-8">
                {{ dataObj.counts.follows || "0" }}人关注
              </span>
              <span class="m-r-8">
                {{ dataObj.counts.browses || "0" }}人浏览
              </span>
              <span class="m-r-8" v-if="!flagType || type == 'goodsDetail'">
                {{ dataObj.counts.consultations || "0" }}人咨询
              </span>
              <span> {{ dataObj.counts.parts || "0" }}件商品 </span>
            </template>
          </div>
          <span v-if="focusTime">
            {{ focusTime + "关注" }}
          </span>
        </div>
        <div class=" flex flex-col items-end justify-center">
          <div
            v-show="$store.state.profile.id"
            class=" flex justify-start items-center space-x-8 cursor-pointer"
          >
            <div
              v-if="!dataObj.followed"
              class="w-16 h-16"
              @click.stop="collect(dataObj)"
            >
              <svg
                t="1667186659379"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1093"
                width="16"
                height="16"
              >
                <path
                  d="M908.8 214.4c-9.6-12.8-19.2-22.4-28.8-32-112-115.2-230.4-105.6-342.4-16-9.6 6.4-19.2 16-28.8 25.6-9.6-9.6-19.2-16-28.8-25.6-112-86.4-230.4-99.2-342.4 16-9.6 9.6-19.2 19.2-25.6 32-134.4 195.2-60.8 387.2 137.6 560 44.8 38.4 89.6 73.6 137.6 102.4 16 9.6 32 19.2 44.8 28.8 9.6 6.4 12.8 9.6 19.2 9.6 3.2 3.2 6.4 3.2 12.8 6.4 3.2 3.2 9.6 3.2 16 6.4 25.6 6.4 64 3.2 89.6-12.8 3.2 0 9.6-3.2 16-9.6 12.8-6.4 28.8-16 44.8-28.8 48-28.8 92.8-64 137.6-102.4C969.6 598.4 1043.2 406.4 908.8 214.4zM736 732.8c-41.6 35.2-86.4 70.4-131.2 99.2-16 9.6-28.8 19.2-44.8 25.6-6.4 3.2-12.8 6.4-16 9.6-6.4 3.2-16 6.4-25.6 9.6-3.2 0-6.4 0-9.6 0-6.4 0-12.8 0-16 0-3.2 0-3.2 0-3.2 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0-3.2 0-3.2-3.2-3.2 0-6.4-3.2-9.6-3.2-3.2-3.2-9.6-6.4-16-9.6-12.8-6.4-28.8-16-44.8-25.6-44.8-28.8-89.6-60.8-131.2-99.2-179.2-160-243.2-323.2-131.2-489.6 6.4-9.6 16-16 22.4-25.6 89.6-96 182.4-86.4 275.2-12.8 9.6 6.4 16 12.8 22.4 19.2 0 0 0 0 0 0l28.8 32c3.2 3.2 3.2 3.2 6.4 6.4 0 0 0 0 0 0l0 0c3.2-3.2 9.6-9.6 16-16 12.8-12.8 25.6-25.6 41.6-38.4 92.8-73.6 185.6-83.2 275.2 12.8 6.4 9.6 16 16 22.4 25.6C982.4 406.4 918.4 572.8 736 732.8z"
                  fill="#8a8a8a"
                  p-id="1094"
                ></path>
              </svg>
            </div>
            <div>{{ dataObj.followed ? "已关注" : "关注" }}</div>
          </div>
          <div class=" flex justify-start items-center space-x-8 cursor-pointer" @click.stop="copyLink(dataObj)">
              <div class="w-16 h-16">
                <svg
                  t="1667186930963"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="1401"
                  width="16"
                  height="16"
                >
                  <path
                    d="M558.933333 699.733333l-170.666666-93.866666c-25.6 21.333333-55.466667 34.133333-89.6 34.133333-72.533333 0-128-55.466667-128-128s55.466667-128 128-128c38.4 0 68.266667 17.066667 93.866666 38.4L597.333333 320V298.666667c0-72.533333 55.466667-128 128-128s128 55.466667 128 128-55.466667 128-128 128c-34.133333 0-64-12.8-85.333333-34.133334l-213.333333 110.933334v21.333333l174.933333 98.133333c21.333333-17.066667 51.2-29.866667 81.066667-29.866666 72.533333 0 128 55.466667 128 128s-55.466667 128-128 128-128-55.466667-128-128c0-4.266667 0-12.8 4.266666-21.333334zM298.666667 554.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666667-42.666667 17.066667-42.666667 42.666667 17.066667 42.666667 42.666667 42.666667z m426.666666-213.333334c25.6 0 42.666667-17.066667 42.666667-42.666666s-17.066667-42.666667-42.666667-42.666667-42.666667 17.066667-42.666666 42.666667 17.066667 42.666667 42.666666 42.666666z m-42.666666 426.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666666-42.666667 17.066667-42.666667 42.666666 17.066667 42.666667 42.666667 42.666667z"
                    fill="#F3D146"
                    p-id="1402"
                  ></path>
                </svg>
              </div>
              <div>分享</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *店铺组件
 * @param {string} type 页面来源 eg: focus:我关注的店铺 history：浏览记录 goodsDetail:商品详情 可不传
 * @param {object} dataObj 渲染数据对象
 */
import { addFocusStoreApi,delFocusStoreApi} from '@/api/focus'
export default {
  name: "storeItem",
  props: ["dataObj", "type", "focusTime", "createdAt"],
  data() {
    return {
      defaultImg: require("@/assets/maintenance/default-store.png"),
      typeClass: "flag_j",
      flagType: this.type == "goodsDetail" ? true : false,
      itemObj: {
        id: 0, ///# 公司ID
        name: "店铺名称", // # 公司名称
        shortName: "", //# 公司简称
        areaId: 0, //# 所属地区ID
        address: "四川省/成都市/双流区", // # 公司地址
        type: "dealer", // # 企业类型 dealer: 经销商 garage: 修理厂 manufacturer: 生产商
        mnemonic: "", // # 助记符
        categories: [
          //# 主营配件分类
          [""],
        ],
        vehBrands: [
          // # 主营车型品牌
          "",
        ],
        brands: [
          // # 主营配件品牌
          "",
        ],
        bizLicenceCode: "", // # 营业执照代码、统一社会信用代码（三证合一）
        licencesImage: "", // # 三证图片地址
        description: "", //# 描述/简介
        contacts: "", // # 联系人
        phone: "", //# 公司电话
        contacts1: "", //	 # 联系人1
        contacts2: "", //	 # 联系人2
        contacts3: "", //	 # 联系人3
        phone1: "", // # 联系电话1
        phone2: "", // # 联系电话2
        phone3: "", //# 联系电话3
        email: "", // # 电子邮箱
        logoImage: "", ///	 # 公司logo图片地址
        images: [],
        wechat: "", //# 微信
        wechatImages: [
          // # 微信图片
          "",
        ],
        qq: "", //# QQ号
        qqImages: [
          //# QQ图片
          "",
        ],
        wechatOffiCccount: "", // # 微信公众号
        wechatOffiCccountImage: "", //	 # 微信公众号二维码
        website: "", //# 公司网站
        paymentCodeImage: "", //# 商家收款码图片地址
        businessCardImage: "", //	 # 企业名片图片地址
        bankName: "", ///# 开户银行
        bankAccount: "", //	 # 银行账号
        emblem: "", //# 公司徽标
        invoiceTitle: "", // # 发票抬头
        invoicePhone: "", // # 发票电话
        autoPartsCity: "成都大气小气汽配商城", //# 所在汽配城
        remarks: "", //# 备注
        counts: {
          //# 数量统计
          parts: 0, //# 上架配件数量
          follows: 0, // # 关注数量
          browses: 0, // # 浏览数量
          consultations: 0, ///# 咨询数量
          shares: 0, //# 分享数量
        },
        followed: false, // # 是否关注店铺
        flagThreesoft: false, //# 是否思锐认证商家
        flagOptimization: false, // # 是否优选商家
        flagPower: false, //# 是否实力商家
      },
    };
  },
  computed: {
    renderCategories() {
      let arr = [];

      const categories = this.dataObj.categories;
      if (categories && categories.length) {
        categories.forEach((p) => {
          arr.push(`${p[0]}>${p[1]}`);
        });
      }
      return arr.join(" | ");
    },
  },
  mounted() {
    // console.log(111, this.focusTime);
    // if (this.dataObj) {
    //   this.dataObj = this.dataObj;
    // }
  },
  methods: {
    renderType() {
      let str = "";
      switch (this.dataObj.type) {
        case "dealer":
          str = "经销商";
          this.typeClass = "flag_j";
          break;
        case "garage":
          str = "汽修厂";
          this.typeClass = "flag_q";
          break;
        case "manufacturer":
          str = "生产厂家";
          this.typeClass = "flag_s";
          break;
      }
      return str;
    },
    collect(e) {
      if (!this.$store.state.profile.id) {
        this.$router.push('/login')
        return
      }
      addFocusStoreApi({
        id:e.id
      }).then(res=>{
        this.$message.success('关注成功')
        this.dataObj.followed = true
      })
    },
    delCollect(e) {
      delFocusStoreApi({
        id:e.id
      }).then(res=>{
        this.$emit('update')
      })
    },
    copyLink(row) {
      const link= `${location.origin}/home/store?storeId=${row.id}&tabsActive=storeInfo`
      const _this = this
      this.$copyText(link).then(
          function(e) {
            _this.$message({
              message: "已复制到剪贴板",
              type: "success",
            });
          },
          function(e) {
            _this.$message({
              message: "复制失败",
              type: "error",
            });
          }
        );
    },
    toStoreDetailFn() {
      let obj = {};
      if (this.dataObj.counts && this.dataObj.counts.parts !== "0") {
        //是否已商家配件
        obj = {
          path: "/home/store",
          query: {
            storeId: this.dataObj.id,
          },
        };
      } else {
        obj = {
          path: "/home/store",
          query: {
            storeId: this.dataObj.id,
            tabsActive: "storeInfo",
          },
        };
      }
      // this.$router.push(obj);
      // 跳转商家，新开tab页
      const routeData = this.$router.resolve(obj);
      window.open(routeData.href, '_blank');
    },
    showImgFn(imgs, index) {},
  },
};
</script>

<style lang="less" scoped>
.card {
  margin: 0 12px 12px;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-align: left;
  .item_box {
    display: flex;
    .item_l {
      margin-right: 12px;

      .store_img {
        width: 204px;
        height: 124px;
        border-radius: 4px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: contain;
        }
        .mask {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 104px;
          height: 16px;
          background: linear-gradient(
            90deg,
            rgba(255, 110, 76, 0.7),
            rgba(255, 177, 45, 0)
          );
          border-radius: 0px 0px 4px 4px;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 16px;
        }
      }
    }
    .item_r {
      flex: 1;
      .item_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          max-width: 130px;
        }
        .threesoft_img {
          width: 20px;
          height: 20px;
        }
        .city {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 16px;
        }
      }
      .item_address {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        line-height: 16px;
      }
      .item_oil {
        width: 211px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 16px;
      }
      .flag_box {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #ff6e4c;
        line-height: 16px;
        .flag_q {
          padding: 0 4px;
          background: #ffeeeb;
          border-radius: 2px;
          color: #ff6e4c;
        }
        .flag_s {
          padding: 0 4px;
          background: #ffeeeb;
          border-radius: 2px;
          color: #ff6e4c;
        }
        .flag_j {
          padding: 0 4px;
          background: #e1f5f0;
          border-radius: 2px;
          color: #3bba98;
        }
        .srrz {
          padding: 0 4px;
          background: #ffffff;
          border: 0.5px solid #ff6e4c;
          border-radius: 2px;
        }
        .advice_txt {
          color: #666666;
        }
      }

      .companyName {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #ff6e4c;
        line-height: 16px;
        display: flex;
        align-items: center;
      }
    }
  }
  .img_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 8px;
    img {
      width: 56px;
      height: 56px;
      border-radius: 4px;
      margin-right: 12px;
      object-fit: cover;
      &:last-child {
        margin-right: 0;
      }
    }
    .m_r_0 {
      margin-right: 0;
    }
    .mask {
      width: 56px;
      height: 56px;
      background: rgba(51, 51, 51, 0.6);
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      .mask_num {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .remark {
    width: 100%;
    height: auto;
    font-size: 12px;
    color: #666666;
    line-height: 16px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #999999;
    line-height: 16px;
    .iconfont {
      font-size: 12px;
    }
  }
}
</style>
