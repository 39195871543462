<template>
  <div class=" bg-black-200 p-16 rounded-md text-white">
    <!-- {{ 商品查询 }} -->
    <queryBlock
      v-if="type === 1"
      name="品牌"
      ref="brand"
      :selected="checkedBrand"
      :sourceData="brandMap"
      @queryChange="brandChange"
    />
    <queryBlock
      v-if="type === 1"
      name="品质"
      ref="quality"
      :selected="checkedQuality"
      :sourceData="propertyMap"
      @queryChange="qualityChange"
    />
    <queryBlock
      v-if="type === 1"
      name="汽配城"
      ref="goodPartsCity"
      :selected="checkedAutoPartsCityGoodMap"
      :sourceData="autoPartsCityMap"
      @queryChange="autoPartsCityGoodChange"
    />
    <div class=" flex items-start" v-if="type === 1">
      <div class="flex-1 overflow-hidden">
        <div class=" text-lg font-semibold">其他</div>
        <div>
          <el-checkbox v-model="query1Extra.thisCity">
            <span class=" text-white">同省市</span>
          </el-checkbox>
          <el-checkbox v-model="query1Extra.hasStock">
            <span class=" text-white">有货</span>
          </el-checkbox>
          <el-checkbox v-model="query1Extra.followStore">
            <span class=" text-white">关注的店铺</span>
          </el-checkbox>
        </div>
      </div>
    </div>
    <!-- 店铺查询 -->
    <queryBlock
      v-if="type === 2"
      name="主营配件品牌"
      ref="storeBrands"
      :selected="checkedBrands"
      :sourceData="brandsMap"
      @queryChange="brandsChange"
    />
    <queryBlock
      v-if="type === 2"
      name="主营车型品牌"
      ref="storeVehBrands"
      :selected="checkedVehBrandsMap"
      :sourceData="vehBrandsMap"
      @queryChange="vehBrandsChange"
    />
    <queryBlock
      v-if="type === 2"
      name="主营配件类别"
      ref="storeCate"
      :selected="checkedCategories"
      :sourceData="categoriesMap"
      @queryChange="categoriesChange"
    />
    <queryBlock
      v-if="type === 2"
      name="汽配城"
      ref="storePartsCity"
      :selected="checkedAutoPartsCityMap"
      :sourceData="autoPartsCityMap"
      @queryChange="autoPartsCityChange"
    />
    <div class=" flex items-start" v-if="type === 2">
      <div class="flex-1 overflow-hidden">
        <div class=" text-lg font-semibold">其他</div>
        <div>
          <el-checkbox v-model="query2Extra.thisCity">
            <span class=" text-white">同省市</span>
          </el-checkbox>
          <el-checkbox v-model="query2Extra.onlyPartsOn">
            <span class=" text-white">有上架商品</span>
          </el-checkbox>
          <el-checkbox v-model="query2Extra.followStore">
            <span class=" text-white">关注的店铺</span>
          </el-checkbox>
        </div>
      </div>
    </div>
    <!-- 选中的商品 -->
    <div
      v-if="type === 1"
      class=" flex justify-start mt-16 bg-white text-black-200 p-8 rounded-md"
    >
      <div class=" w-125 mr-16">为你找到以下商品</div>
      <div class=" flex-1 mr-16 divide-solid divide-black-100 divide-x-2 ">
        <span class="text-center mr-8" v-if="checkedBrand.length">{{
          checkedBrand.join("、")
        }}</span>
        <span class="text-center pl-8 mr-8" v-if="checkedQuality.length">{{
          checkedQuality.join("、")
        }}</span>
        <span class="text-center pl-8 mr-8" v-if="query1Extra.thisCity"
          >同省市</span
        >
        <span class="text-center pl-8 mr-8" v-if="query1Extra.hasStock"
          >有货</span
        >
        <span class="text-center pl-8 mr-8" v-if="query1Extra.followStore"
          >关注的店铺</span
        >
      </div>
      <div class=" w-76 cursor-pointer" @click="clearQuery1">清空条件</div>
    </div>
    <!-- 选中的店铺 -->
    <div
      v-if="type === 2"
      class=" flex justify-start mt-16 bg-white text-black-200 p-8 rounded-md"
    >
      <div class=" w-125 mr-16">为你找到以下店铺</div>
      <div class=" flex-1 mr-16 divide-solid divide-black-100 divide-x-2 ">
        <span class="text-center mr-8" v-if="checkedBrands.length">{{
          checkedBrands.join("、")
        }}</span>
        <span class="text-center pl-8 mr-8" v-if="checkedVehBrandsMap.length">{{
          checkedVehBrandsMap.join("、")
        }}</span>
        <span class="text-center pl-8 mr-8" v-if="checkedCategories.length">{{
          checkedCategories.join("、")
        }}</span>
        <span
          class="text-center pl-8 mr-8"
          v-if="checkedAutoPartsCityMap.length"
          >{{ checkedAutoPartsCityMap.join("、") }}</span
        >
        <span class="text-center pl-8 mr-8" v-if="query2Extra.thisCity"
          >同省市</span
        >
        <span class="text-center pl-8 mr-8" v-if="query2Extra.onlyPartsOn"
          >有上架商品</span
        >
        <span class="text-center pl-8 mr-8" v-if="query2Extra.followStore"
          >关注的店铺</span
        >
      </div>
      <div class=" w-76 cursor-pointer" @click="clearQuery2">清空条件</div>
    </div>
  </div>
</template>
<script>
import queryBlock from "./queryBlock.vue";
import { getDict, getBrands, getVehBrands, getCategories } from "@/api/basic";
import { getGoodsFilterApi } from "@/api/home/goods";
import { postCompanyListFilter } from "@/api/home/company";

export default {
  components: {
    queryBlock,
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    keyword: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      default: () => {},
    },
    companyType: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      brandMap: [],
      brandsMap: [],
      vehBrandsMap: [],
      propertyMap: [],
      categoriesMap: [],
      autoPartsCityMap: [],
      checkedAutoPartsCityGoodMap: [],
      query1Extra: {
        thisCity: false,
        hasStock: true,
        followStore: false,
      },
      query2Extra: {
        thisCity: false,
        onlyPartsOn: false,
        followStore: false,
      },
      checkedBrand: [],
      checkedQuality: [],
      checkedBrands: [],
      checkedVehBrandsMap: [],
      checkedCategories: [],
      checkedAutoPartsCityMap: [],
    };
  },
  watch: {
    query1Extra: {
      handler: function(newVal) {
        this.makeGoodsQueryForm();
      },
      deep: true,
    },
    query2Extra: {
      handler: function(newVal) {
        this.makeStoreQueryForm();
      },
      deep: true,
    },
    keyword: {
      handler: function(newVal) {
        if (newVal && this.type === 1) {
          this.makeGoodsFilter();
        }
        if (this.type === 2) {
          this.makeStoreFilter();
        }
      },
    },
    query: {
      handler: function(val) {
        this.initQuery(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.initQuery(this.query);
    if (this.keyword && this.type === 1) {
      this.makeGoodsFilter();
    }
    if (this.type === 2) {
      console.log("makeStoreFilter");
      this.makeStoreFilter();
    }
  },
  methods: {
    initQuery(val) {
      if (val && this.type === 1) {
        this.checkedAutoPartsCityGoodMap = val.autoPartsCity
          ? val.autoPartsCity.split(",")
          : [];
        this.checkedBrand = val.brand ? val.brand.split(",") : [];
        this.checkedQuality = val.quality ? val.quality.split(",") : [];
        this.query1Extra = {
          thisCity: val.thisCity,
          hasStock: val.hasStock,
          followStore: val.followStore,
        };
      }
      if (val && this.type === 2) {
        console.log(val);
        this.checkedAutoPartsCityMap = val.autoPartsCity || [];
        this.checkedBrands = val.brands|| [];
        this.checkedVehBrandsMap = val.vehBrands|| [];
        this.checkedCategories = val.categories|| [];
        this.query2Extra = {
          thisCity: val.thisCity,
          onlyPartsOn: val.onlyPartsOn,
          followStore: val.followStore,
        };
      }
    },
    brandChange(e) {
      this.checkedBrand = e;
      this.makeGoodsQueryForm();
    },
    qualityChange(e) {
      this.checkedQuality = e;
      this.makeGoodsQueryForm();
    },
    makeGoodsQueryForm() {
      this.$emit("queryChange", {
        ...this.query1Extra,
        thisProvince: this.query1Extra.thisCity,
        autoPartsCity: this.checkedAutoPartsCityGoodMap.join(","),
        brand: this.checkedBrand.join(","),
        quality: this.checkedQuality.join(","),
      });
    },
    autoPartsCityGoodChange(e) {
      this.checkedAutoPartsCityGoodMap = e;
      this.makeGoodsQueryForm();
    },
    async makeGoodsFilter() {
      const res3 = await getDict({
        type: "autoPartsCity",
      });
      if (!res3) {
        this.autoPartsCityMap = [];
      } else {
        this.autoPartsCityMap = res3.map((item) => item.name) || [];
      }
      getDict({
        type: "quality",
      }).then((res) => {
        if (!res) {
          this.propertyMap = [];
        }
        this.propertyMap = res.map((item) => item.name) || [];
      });
      getBrands({}).then((res) => {
        if (!res) {
          this.brandMap = [];
        }
        this.brandMap = res.map((item) => item.name) || [];
      });
    },
    clearQuery1() {
      this.checkedBrand = [];
      this.checkedQuality = [];
      this.$refs.brand.reset();
      this.$refs.quality.reset();
      this.query1Extra = {
        thisCity: false,
        hasStock: false,
        followStore: false,
      };
      this.makeGoodsQueryForm();
    },
    brandsChange(e) {
      this.checkedBrands = e;
      this.makeStoreQueryForm();
    },
    vehBrandsChange(e) {
      this.checkedVehBrandsMap = e;
      this.makeStoreQueryForm();
    },
    categoriesChange(e) {
      this.checkedCategories = e;
      this.makeStoreQueryForm();
    },
    autoPartsCityChange(e) {
      this.checkedAutoPartsCityMap = e;
      this.makeStoreQueryForm();
    },
    makeStoreQueryForm() {
      this.$emit("storeQueryChange", {
        ...this.query2Extra,
        thisProvince: this.query2Extra.thisCity,
        brands: this.checkedBrands,
        vehBrands: this.checkedVehBrandsMap,
        categories: this.checkedCategories,
        autoPartsCity: this.checkedAutoPartsCityMap,
        type: this.companyType,
      });
    },
    async makeStoreFilter() {
      const res = await getBrands({});
      const res2 = await getVehBrands();
      const res3 = await getDict({
        type: "autoPartsCity",
      });
      const res4 = await getCategories({});
      if (!res) {
        this.brandsMap = [];
      } else {
        this.brandsMap = res.map((item) => item.name) || [];
      }
      if (!res2) {
        this.vehBrandsMap = [];
      } else {
        this.vehBrandsMap = res2.map((item) => item.name) || [];
      }
      if (!res3) {
        this.autoPartsCityMap = [];
      } else {
        this.autoPartsCityMap = res3.map((item) => item.name) || [];
      }
      if (!res4) {
        this.categoriesMap = [];
      } else {
        this.categoriesMap = res4.map((item) => item.name) || [];
      }
      // postCompanyListFilter({
      //   keyword: this.keyword,
      //   type: this.companyType,
      // }).then((res) => {
      //   if (!res) {
      //     this.brandsMap = [];
      //     this.vehBrandsMap = [];
      //     this.autoPartsCityMap = [];
      //     this.categoriesMap = [];
      //   }
      //   const { autoPartsCity, brands, categories, vehBrands } = res;
      //   this.brandsMap = brands || [];
      //   this.vehBrandsMap = vehBrands || [];
      //   this.autoPartsCityMap = autoPartsCity || [];
      //   this.categoriesMap = categories || [];
      // });
    },
    clearQuery2() {
      this.checkedBrands = [];
      this.checkedVehBrandsMap = [];
      this.checkedCategories = [];
      this.checkedAutoPartsCityMap = [];
      this.$refs.storeBrands.reset();
      this.$refs.storeVehBrands.reset();
      this.$refs.storeCate.reset();
      this.$refs.storePartsCity.reset();
      this.query2Extra = {
        thisCity: false,
        onlyPartsOn: false,
        followStore: false,
      };
      this.makeStoreQueryForm();
    },
  },
};
</script>
