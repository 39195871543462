<template>
  <div class="w-full max-w-5xl m-auto" style=" height:calc(100vh - 48px)">
    <!-- 输入栏 -->
    <div class="flex justify-start items-center space-x-8 py-8">
      <div class="w-66 text-center">
        <img class=" w-56 h-56" src="@/assets/logo.png" alt="" />
      </div>
      <div class="flex-1">
        <div
          class="flex-1 flex relative  justify-between h-56  rounded-full overflow-hidden border-warning bg-warning border-4"
        >
          <input
            type="text"
            v-model="keyword"
            placeholder="公司名称、汽配城"
            class="flex-1  rounded-l-full pl-32  border-none text-lg"
            style="height:48px;line-height:48px;outline: none;"
          />
          <div
            class=" w-102 text-center text-xl px-8 text-warnings border-warning bg-warning cursor-pointer"
            style="line-height:48px"
            @click="setKeyword('')"
          >
            搜索
          </div>
        </div>
        <div class="py-8">
          <span
            class="px-4 border rounded-md bg-white cursor-pointer"
            v-for="(item, index) in storeHis"
            :key="index"
            @click="setKeyword(item.keyword)"
            >{{ item.keyword }}</span
          >
        </div>
      </div>
    </div>
    <!-- 过滤 -->
    <div class=" mb-16">
      <QueryForm
        :type="queryType"
        :keyword="keyword"
        @storeQueryChange="queryStoreChange"
      />
    </div>
    <StoreDetail :query="storeQueryInfo"/>
  </div>
</template>
<script>
import QueryForm from "../components/queryForm.vue";
import StoreDetail from "../search/components/storeDetail.vue";
import { historyLogsListApi } from "@/api/home/log";
export default {
  components: {
    QueryForm,
    StoreDetail,
  },
  data() {
    return {
      queryType: 2,
      goodsHis: [],
      storeHis: [],
      exact: false,
      goodsKeyword: "",
      keyword: "",
      filter: {},
      goodsQueryInfo: {},
      storeQueryInfo: {},
      goodsKeywordSetted: false,
      storeKeywordSetted: false,
      goodsData: [],
    };
  },
  mounted() {
    this.initHis();
  },
  methods: {
    initHis() {
      historyLogsListApi({
        type: "searchStore",
      }).then((res) => {
        this.storeHis = res;
      });
    },
    setKeyword(keyword) {
      this.storeKeywordSetted = true;
        this.queryStoreChange({});
        this.keyword = keyword;
    },
    handleChangeExact(e) {
      this.exact = e;
    },
    queryStoreChange(query) {
      // const {} = query
      this.storeQueryInfo = {
        keyword: this.keyword,
        page: 1,
        pageSize: 20,
        ...query,
      };
    },
  },
};
</script>
