<template>
  <div
    style="height:700px"
    v-infinite-scroll="load"
    infinite-scroll-disabled="false"
    infinite-scroll-immediate="true"
    infinite-scroll-distance="1"
    class=" overflow-y-auto"
  >
    <StoreItem
      v-for="(item, index) in tableData"
      :key="index"
      :dataObj="item"
      @update="update"
    />
  </div>
</template>
<script>
import StoreItem from "./storeItem.vue";
import { postCompanyList } from "@/api/home/company";
import { createHistoryLogsApi } from "@/api/home/log";
export default {
  components: {
    StoreItem,
  },
  props: {
    query: {
      type: Object,
      default: () => {},
    },
    companyType: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      totalPage: 1,
      page: 1,
      pageSize: 8,
      flag: false,
      resetFlag: false,
    };
  },
  watch: {
    query: {
      handler: function(val) {
        this.page = 1;
        if (this.flag) {
          return;
        }
        if (val.keyword) {
          createHistoryLogsApi({
            type: "searchStore",
            content: {
              ...val,
            },
          });
        }
        this.flag = true;
        this.resetFlag = true;
        this.tableData = [];
        this.queryData();
      },
      deep: true,
    },
    show: {
      handler: function(val) {
        if (val) {
          this.tableData = [];
          this.queryData();
        }
      },
    },
  },
  mounted() {
    // this.queryData();
  },
  methods: {
    queryData() {
      postCompanyList({
        pageSize: this.pageSize,
        ...this.query,
        page: this.page,
        type: this.companyType,
      })
        .then((res) => {
          if (res.rows) {
            this.tableData = [...this.tableData, ...(res.rows || [])];
          }
          this.totalPage = res.totalPage || 1;
        })
        .finally(() => {
          this.flag = false;
        });
    },
    load() {
      if (this.resetFlag) {
        this.resetFlag = false;
        return;
      }
      if (this.page == this.totalPage) return;
      this.page = this.page + 1;
      this.queryData();
    },
    update() {
      this.queryData();
    },
  },
};
</script>
